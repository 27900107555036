import { GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
//Fields : Title	Total Sold	Total Paid	Balance Due	Start Date	End Date	Status	Applicants	Forms	Actions
export const eventsColumns: GridColDef[] = [
  { field: "id", headerName: "ID", width: 50 },
  {
    field: "title",
    headerName: "Title",
    //  width: 130,
    width: 150,
  },
  {
    field: "full_name",
    headerName: "Event Full Name",
     width: 450,
  },
  {
    field: "start_date",
    headerName: "Start Date",
     width: 130,

    // renderCell: (params: GridValueGetterParams) => {
    //   return (<span>{`${params.row.address}, ${params.row.country}`}</span>);
    // },
  },
  {
    field: "end_date",
    headerName: "End Date",
     width: 130,

  },
  {
    field: "link",
    headerName: "Link",
     width: 300,
  },  
  {
    field: "tax",
    headerName: "Tax(%)",
     width: 70,
  },
  // {
  //   field: "status",
  //   headerName: "Status",
  //    width: 130,

  // },  
  // {
  //   field: "applicants",
  //   headerName: "Applicants",
  //    width: 100,
  // },  
  
  // {
  //   field: "total_sold",
  //   headerName: "Total Sold",
  //    width: 100,

  // },
  // {
  //   field: "total_paid",
  //   headerName: "Total Paid",
  //    width: 100,

  // },
  // {
  //   field: "balance_due",
  //   headerName: "Balance Due",
  //    width: 100,

  // },
  // {
  //   field: "forms",
  //   headerName: "Forms",
  //   width: 130,
  // },
];
